.cookie-policy {
  position: relative;
  text-align: center;
  padding: 1.5rem;
  color: #0085ca;
}

.cookie-policy p {
  margin: 0.5rem 0;
}

.cookie-policy p a {
  color: inherit;
  font-weight: bold;
}

.cookie-policy__agree {
  text-align: center;
  font-size: 16px;
  color: #0085ca;
  text-decoration: none;
  border: 0.5px solid #0085ca;
  padding: 10px 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.cookie-policy__agree:hover,
.cookie-policy__agree:focus {
  color: #fff;
  background-color: #0085ca;
}

@media (min-width: 48em) {
  .cookie-policy__agree {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .cookie-policy {
    padding: 1.5rem 5rem;
  }

  .cookie-policy p {
    text-align: left;
    width: calc(100% - 200px);
  }
}

hr {
  border-color: #cccccc;
}
