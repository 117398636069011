@media screen and (max-width: 60em) {
  .home .video-container {
    float: none;
    margin: 0 auto;
    width: 96%;
    padding-right: 0;
    padding-bottom: 42.25%;
    margin-bottom: 20px;
  }

  .homepage_image_container {
    float: none;
    margin: 0 auto;
    padding: 0;
    width: 96%;
    margin-bottom: 25px;
  }

  .homepage_content_text {
    width: 96%;
    margin: 0 auto;
    padding-left: 0;
  }

  .homepage_links {
    height: auto;
    padding-bottom: 30px;
  }

  .homepage_link_item {
    margin-top: 30px;
  }

  .homepage_content {
    padding: 30px 0;
  }

  .inside_content {
    padding: 30px 2%;
  }

  .homepage_message {
    height: 244px;
  }

  .homepage_message .homepage_message_header {
    margin-top: 30px;
  }

  .homepage_message p {
    padding: 0 2%;
  }

  footer .logo {
    margin-top: 0;
  }

  footer .wrapper > a {
    position: absolute;
    bottom: 20px;
    margin-left: 2%;
  }

  footer .footer_tasgov {
    bottom: 20px;
    right: 2%;
  }

  footer nav {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 20px;
    bottom: auto;
    width: 535px;
  }

  footer .aoc {
    margin-bottom: 145px;
    padding-left: 20px;
    padding-right: 20px;

    &-body {
      flex-direction: column;
      gap: 0;
    }
  }

  footer nav ul li:last-child {
    padding-right: 0;
  }

  .header_top {
    position: relative;
  }

  .header_bottom h1 {
    font-size: 2em;
  }

  .home .header_bottom {
    height: 140px;
  }

  .header_bottom h1,
  .header_bottom h2 {
    max-width: 500px;
  }

  .header_logo {
    max-width: 200px;
  }

  header nav {
    right: auto;
    width: 100%;
    text-align: center;
    bottom: 10px;
  }

  header nav ul li a {
    font-size: 1em;
  }

  header nav ul li {
    margin-right: 20px;
  }

  .header_top .wrapper > a {
    margin-left: 2%;
  }
}
