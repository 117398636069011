@media screen and (max-width: 37.5em) {
  .home .video-container {
    float: none;
    margin: 0 auto;
    width: 96%;
    padding-right: 0;
    padding-bottom: 42.25%;
    margin-bottom: 20px;
  }

  .homepage_image_container {
    float: none;
    margin: 0 auto;
    padding: 0;
    width: 96%;
    margin-bottom: 20px;
  }

  .homepage_content_text {
    width: 96%;
    margin: 0 auto;
    padding-left: 0;
  }

  .homepage_links {
    height: auto;
    padding-bottom: 30px;
  }

  .homepage_link_item {
    margin-top: 30px;
  }

  .homepage_content {
    padding: 30px 0;
  }

  .inside_content {
    padding: 30px 2%;
  }

  .homepage_message {
    height: 244px;
  }

  .homepage_message .homepage_message_header {
    margin-top: 30px;
  }

  .homepage_message p {
    padding: 0 2%;
  }

  footer .logo {
    margin-top: 0;
  }

  footer .wrapper > a {
    position: absolute;
    bottom: 20px;
    margin-left: 2%;
  }

  footer .footer_tasgov {
    bottom: 20px;
    right: 2%;
  }

  footer nav {
    display: none;
    margin: 0 auto;
    position: relative;
    top: 20px;
    bottom: auto;
    width: 535px;
  }

  footer nav ul li:last-child {
    padding-right: 0;
  }

  footer .aoc {
    margin-bottom: 145px;
    padding-left: 20px;
    padding-right: 20px;

    &-body {
      flex-direction: column;
      gap: 0;
    }
  }

  .header_top {
    position: relative;
    height: auto;
    min-height: 125px;
  }

  .header_bottom h1 {
    font-size: 1.5em;
  }

  .home .header_bottom {
    height: 100px;
  }

  .header_bottom h1,
  .header_bottom h2 {
    max-width: 500px;
  }

  .header_logo {
    max-width: none;
    padding-bottom: 14px;
  }

  header nav {
    right: auto;
    width: 100%;
    text-align: center;
    bottom: 10px;
    position: relative;
    display: none;
  }

  header nav ul li a {
    font-size: 1em;
    line-height: 50px;
    display: block;
  }

  header nav ul li {
    margin-right: 0px;
    display: block;
    width: 100%;
    height: 50px;
  }

  .header_top .wrapper {
    text-align: center;
  }

  .header_top .wrapper > a {
    display: inline-block;
  }

  .homepage_message .homepage_message_header {
    font-size: 1.5em;
    margin-top: 20px;
  }

  .homepage_message .wrapper .orange-bar {
    margin-bottom: 15px;
  }

  .homepage_message p {
    font-size: 0.925em;
  }

  .mobile_nav {
    font-weight: 700;
    text-align: left;
    clear: both;
    height: 30px;
    display: block;
    padding: 20px;
    cursor: pointer;
    font-size: 1.5em;
    color: #0085ca;
    background: url(https://www.t21.net.au/__data/assets/image/0009/37584/hamburger.png)
      no-repeat right 20px top 20px;
  }
}

/* Media query for just before iPad portrait width (767px) */
@media screen and (max-width: 47.9375em) {
  .homepage_links .wrapper {
    flex-direction: column;
  }

  .homepage_link_item {
    width: 100%;
  }
}
